/* Fullscreen video background */
.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensures the video stays behind the content */
    overflow: hidden;
}

.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* Ensures the video covers the entire background without distortion */
    pointer-events: none; /* Prevents interactions with the video */
}

/* Content overlay */
.content-overlay {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mobile-image {
    display: none;
}
/* Footer styling to make sure it's on top */
.footer {
    position: relative;
    z-index: 2;
    padding-bottom: 20px;
}
@media screen and (max-width:768px) {
    /* .content-overlay {
        background-image: url('./img/img.avif') !important;
        background-repeat: no-repeat;
        background-size: cover;
    } */
    @media screen and (max-width: 768px) {
        .video-background {
            display: none; /* Hide video */
        }
    
        /* Show image in mobile view */
        .mobile-image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            z-index: -1; /* Keep image behind the content */
        }
    }
    
    .video-background{
        display: none;
    }
}
